<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <v-card elevation="1">
        <div class="wrapper">
          <h1 class="mb-2">Confirmação de Backup</h1>
          <h4 class="mb-4">
            A geração do backup pode levar alguns minutos. Durante esse período, não
            feche esta tela até que o processo seja finalizado. Deseja continuar e
            gerar o backup agora?
          </h4>
          <v-btn outlined @click="exportBackup()" :loading="loading">
            Gerar backup!
          </v-btn>
        </div>
      </v-card>
    </div>
        <LoadingBackup
          :dialog="loading"
          :dialogTitle="dialogTitle"
          :dialogMessage="dialogMessage"
        />
        <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { exportProduct } from "@/services/product.js";
export default {
  name: "ExportarBackup",
  data: () => ({
    breadcrumbs: [
      {
        text: "Exportar Backup",
        disabled: true,
        to: "",
      },
    ],
    loading: false,
    messageError: "Ocorreu um erro ao gerar o backup",
    error: false,
    dialogTitle: "Gerando Backup...",
    dialogMessage:
      "Aguarde alguns minutos enquanto o backup está sendo gerado. Não feche esta tela até que o processo seja concluído. Assim que o backup for finalizado, o download do arquivo será iniciado automaticamente.",
  }),
  methods: {
    async exportBackup() {
      try {
        this.loading = true;
        const resp = await exportProduct();
        if (resp.data.url) {
          window.open(resp.data.url);
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  flex-direction: column;
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
}
</style>
